import { AuthService } from './../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
  selector: 'app-users-list-resumen',
  templateUrl: './users-list-resumen.component.html',
  styleUrls: ['./users-list-resumen.component.css']
})
export class UsersListResumenComponent implements OnInit {

  load: any;
  user: any;
  users: any;
  courses: any;
  current: any;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private location: Location,
    private auth: AuthService,
  ) { }

  async ngOnInit() {
    // this.load = this.route.snapshot.data;
    this.current = this.route.snapshot.paramMap.get('client_id');
    this.courses = await this.api.getCourses().toPromise();
    this.courses = this.courses.object;
    this.courses = _.filter(this.courses, { active: 1 });
    this.courses = _.orderBy(this.courses, 'position', 'asc');
    this.users = await this.api.getResumeUser(this.current).toPromise();
    this.users = this.users.object;
    this.users = _.filter(this.users, { active: 1 });
    this.users.map(async (user) => {
      user.respuestas = JSON.parse(JSON.stringify(this.courses));
      const currentUser = `${user.detail[0].name} ${user.detail[0].last_name}`;
      let ponderacion = 0;
      // if (!_.isEmpty(user.courses)) {
        user.respuestas = _.orderBy(user.respuestas, 'position', 'asc');
        user.respuestas.map((course) => {
          const ponderations = this.courses.find(c => {
            return c.id === course.id;
          });
          const currentCourse = user.courses.find(c => {
            return c.course_id === course.id;
          });
          if (currentCourse && course.id == currentCourse.course_id) {
            if (currentCourse.approved === 1) {
              course.approved = 'si';
              if (!ponderations.introduction) {
                ponderacion += parseFloat(ponderations.ponderation_approved);
                // console.log('ponderation_approved-' + user.id + "--" + parseFloat(ponderations.ponderation_approved)  + "--" + currentCourse.id + "--" + course.id);
              }
            } else if (currentCourse.approved === 0 && currentCourse.try > 0) {
              course.approved = 'no';
              if (!ponderations.introduction) {
                ponderacion += parseFloat(ponderations.ponderation_repproved);
                // console.log('ponderation_repproved-' + user.id + "--" + parseFloat(ponderations.ponderation_repproved) + "--" + currentCourse.id + "--" + course.id);
              }
            }
            else if (currentCourse.approved === 0 && !currentCourse.try) {
              course.approved = '-';
              if (!ponderations.introduction) {
                // console.log('ponderation_-' + user.id + "--"  + 0 + "--" + currentCourse.id + "--" + course.id);
                ponderacion += 0;
              }
            }
            // course.approved = currentCourse.approved && course.finish_course !== null;
          }
        });
        console.log(user.id + "-", ponderacion);
        user.respuestas[0].alumno = currentUser;
        user.approved = Math.round(ponderacion);
      // }
    })
    console.log(this.users);
    
  }

  goBack() {
    // window.history.back();
    this.location.back();
  }

}
