import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ApiService } from '../../../../services/api.service';
import * as _ from 'lodash';
import { DetailComponent } from '../detail/detail.component';
import { UsersComponent } from '../users/users.component';
@Component({
  selector: 'app-aproved',
  templateUrl: './aproved.component.html',
  styleUrls: ['./aproved.component.css']
})
export class AprovedComponent implements OnInit {
  displayedColumns: string[] = ['date', 'users', 'client', 'price', 'end', 'actions'];
  dataSource: any;
  requests: any;
  hide1: any;
  value: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private api: ApiService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.loadUsers();
    this.paginator._intl.itemsPerPageLabel = 'Items por página:';
    this.paginator._intl.firstPageLabel = 'Primera página';
    this.paginator._intl.lastPageLabel = 'Última página';
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator.pageSize = 20;
  }
  async loadUsers() {
    this.dataSource = new MatTableDataSource([]);
    this.requests = await this.api.getClientRequests().toPromise();
    this.requests = this.requests.object;
    this.requests = _.filter(this.requests, { aproved: 1 });
    this.renderTable();
  }
  renderTable() {
    this.dataSource = new MatTableDataSource(this.requests);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  async setStatus(userId: any) {
    await this.api.setUserStatus(userId).toPromise();
    this.router.navigated = false;
    this.router.navigate([this.router.url]).then(() => {
      this.ngOnInit();
    });

  }

  async deleteUser(userId: any) {
    await this.api.deleteUser(userId).toPromise();
    this.router.navigated = false;
    this.router.navigate([this.router.url]).then(() => {
      this.ngOnInit();
    });
  }


  details(element): void {
    const dialogRef = this.dialog.open(DetailComponent, {
      width: '750px',
      data: { title: 'Aceptar', element: element },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.loadUsers();

      } else {
      }
    });
  }
  users(element): void {
    const dialogRef = this.dialog.open(UsersComponent, {
      width: '750px',
      data: { title: 'Aceptar', element: element },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.loadUsers();

      } else {
      }
    });
  }
}
