import { element } from 'protractor';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
  price: any;
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<DetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
  ) { }
  ngOnInit(): void {
    console.log('Element', this.data);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onAprove() {
    console.log('Precio', this.price);
    const data = {
      price: this.price,
    }
    this.loading = true;
    this.api.updateClientRequest(this.data.element.id, data).subscribe(res => {
      if (res.code === 200) {
        this.loading = false;
        this.dialogRef.close(this.data.element);
      }
    }, (err) => {
      this.loading = false;
    });
  }
}
