import { UsersListResumenComponent } from './clients/users/users-list-resumen/users-list-resumen.component';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { registerLocaleData } from '@angular/common';
import localeEsCL from '@angular/common/locales/es-CL';
import { Select2Module } from 'ng2-select2';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
registerLocaleData(localeEsCL);

// Material
import {
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatSelectModule,
  MatMenuModule,
  MatProgressBarModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatTabsModule,
  MatNativeDateModule,
  MatCardModule,
  MatRadioModule,
  MatIconModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatListModule,


} from '@angular/material';

import { NgxPaginationModule } from 'ngx-pagination';
import { RequestsComponent } from './views/pages/requests/requests.component';
import { AdminStudentsComponent } from './views/pages/admin-students/admin-students.component';
import { DetailComponent } from './views/pages/requests/detail/detail.component';
import { AdminDashboardComponent } from './views/pages/admin-dashboard/admin-dashboard.component';
import { AprovedComponent } from './views/pages/requests/aproved/aproved.component';
import { UsersComponent } from './views/pages/requests/users/users.component';
import { VideoComponent } from './modals/video/video.component';
import { ModulesComponent } from './views/pages/modules/modules.component';
import { ResponseHandlerInterceptor } from './interceptors/response-handler.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    RequestsComponent,
    AdminStudentsComponent,
    DetailComponent,
    AdminDashboardComponent,
    AprovedComponent,
    UsersComponent,
    UsersListResumenComponent,
    ModulesComponent
    // VideoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    Select2Module,
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    NgxPaginationModule,
    NgxMatSelectSearchModule,
    MatListModule,
  ],
  entryComponents: [
    DetailComponent,
    UsersComponent,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-cl'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseHandlerInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
