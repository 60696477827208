import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { ApiService } from '../../../services/api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-admin-students',
  templateUrl: './admin-students.component.html',
  styleUrls: ['./admin-students.component.css']
})
export class AdminStudentsComponent implements OnInit {
  displayedColumns: string[] = ['detail.name', 'detail.last_name', 'email', 'client', 'actions'];
  dataSource: any;
  users: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private api: ApiService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.loadUsers();
    this.paginator._intl.itemsPerPageLabel = 'Items por página:';
    this.paginator._intl.firstPageLabel = 'Primera página';
    this.paginator._intl.lastPageLabel = 'Última página';
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator.pageSize = 20;
  }
  async loadUsers() {
    this.dataSource = new MatTableDataSource([]);
    this.users = await this.api.getUsers().toPromise();
    this.users = this.users.object;
    console.log('Users', this.users);
    this.users = _.filter(this.users, { student: 1 });
    this.renderTable();
  }
  renderTable() {
    this.dataSource = new MatTableDataSource(this.users);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  async setStatus(userId: any) {
    console.log('User ID', userId);
    await this.api.setUserStatus(userId).toPromise();
    this.router.navigated = false;
    this.router.navigate([this.router.url]).then(() => {
      this.ngOnInit();
    });

  }

  async deleteUser(userId: any) {
    await this.api.deleteUser(userId).toPromise();
    this.router.navigated = false;
    this.router.navigate([this.router.url]).then(() => {
      this.ngOnInit();
    });
  }

}
