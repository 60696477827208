
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) { }

  // canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
  //   return this.auth.user.pipe(
  //     take(1),
  //     map(user => {
  //       console.log('Uusario en guard', user);
  //       if (!user) {
  //         console.warn('No login');
  //         this.router.navigated = false;
  //         this.router.navigate(['/login']).then(() => {

  //         });
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     })
  //   )
  // }

  async canActivate() {
    if (!await this.auth.checkAuthenticated()) {
      // if (this.router.url)
      await this.router.navigate(['/auth']);
      return false;
    }
    return true;
  }
}
