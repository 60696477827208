export const statusErros: number[] = [400, 401, 404, 405];

// export const MapVerification: Map<string, string> = [{'new Map<string, string>();'}]

export enum EStatus {
    VERIFICATE = 202,
    OK = 200
}

export enum EVerification {
    EMAIL_SENT = 'email_sent',
    PENDING = 'pending',
    NOT_FOUND = 'not_found',
    VERIFIED = 'verified',
    ALREADY_VERIFIED = 'already_verified'
}

export enum EStatusProcess {
    DATA_INVALID = 'Los datos ingresados no son válidos.',
    ERROR_PROCESS = 'Ocurrió un error al procesar los datos.'
}

export const errorMessages: Map<string, string> = new Map<string, string>([
    [ EVerification.EMAIL_SENT, 'Se le ha enviado un correo para verificar su email, favor de validar.' ],
    [ EVerification.PENDING, 'Su correo aún no ha sido verificado, favor de revisar su bandeja de correo para verificar su email.' ],
    [ EVerification.NOT_FOUND, 'Su email no ha sido verificado, código inválido.' ],
    [ EVerification.VERIFIED, 'Su email ha sido verificado con éxito.' ],
    [ EVerification.ALREADY_VERIFIED, 'Su email ya ha sido verificado anteriormente.' ],
  ]);

  export enum EStatusMessage {
      SUCCESS = 'success',
      WARNING = 'warning',
      DANGER = 'danger'
  }
  export const statusMessage: Map<string, string> = new Map<string, string>([
    [ EVerification.EMAIL_SENT, EStatusMessage.SUCCESS ],
    [ EVerification.PENDING, EStatusMessage.WARNING ],
    [ EVerification.NOT_FOUND, EStatusMessage.DANGER ],
    [ EVerification.VERIFIED, EStatusMessage.SUCCESS ],
    [ EVerification.ALREADY_VERIFIED, EStatusMessage.SUCCESS ],
  ]);