import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { mergeMap, tap, map } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';

import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ERole } from '../constants';

// const API_USERS_URL = 'http://localhost:8000/api/login';
// const API_USERS_URL = 'https://api-capacitaciones.kulltest.cl/api/login';
const API_USERS_URL = `${environment.API_USERS_URL}/api/login`;
const API_USERS_URL_EMAIL = `${environment.API_USERS_URL}/api/loginWithout`;
// const API_USERS_URL = 'https://api-capacitaciones.test/api/login';
@Injectable({
  providedIn: 'root'
})

export class AuthService {

  public isAuthenticated = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private _snackBar: MatSnackBar,
  ) { }

  async checkAuthenticated() {
    const authenticated = localStorage.getItem('access_token');
    if (authenticated) {
      const admin = JSON.parse(localStorage.getItem('current_user'));
      if (admin.active === 1) {
        this.isAuthenticated.next(true);
        return true;
      }
      localStorage.removeItem('access_token');
      localStorage.removeItem('current_user');
      // this._snackBar.open('Tu usuario no se encuentra activo', '', {
      //   duration: 2000,
      // });
      return false;
    }
    return false;
  }

  // Authentication/Authorization
  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(API_USERS_URL, { email, password })
      .pipe(
        map((res: any) => {
          //this.store.dispatch(new UserLoaded({ user: res.user }));
          if (res && res.user && res.token) {
            localStorage.setItem('current_user', JSON.stringify(res.user));
            localStorage.setItem('access_token', JSON.stringify(res.token));
          }
          return res;
        })
      );
  }

  withOutLogin(code: string): Observable<any> {
    return this.http.post<any>(API_USERS_URL_EMAIL, { code })
      .pipe(
        map((res: any) => {
          //this.store.dispatch(new UserLoaded({ user: res.user }));
          if (res && res.user && res.token) {
            localStorage.setItem('current_user', JSON.stringify(res.user));
            localStorage.setItem('access_token', JSON.stringify(res.token));
          }
          return res;
        })
      );
  }

  getCurrentUser() {
    const userString = localStorage.getItem('current_user');
    if (!isNullOrUndefined(userString)) {
      const user = JSON.parse(userString);
      return user;
    }
    return null;
  }

  isLogged() {
    const userString = localStorage.getItem('current_user');
    if (!isNullOrUndefined(userString)) {
      return true;
    }
    return false;
  }

  getUserName() {
    const userString = localStorage.getItem('current_user');
    if (!isNullOrUndefined(userString)) {
      const user = JSON.parse(userString);
      if (!isNullOrUndefined(user.detail['0'])) {
        return user.detail['0'].name;
      }
      return 'John Doe';
    }
    return null;
  }
  getRole() {
    const userString = localStorage.getItem('current_user');
    if (!isNullOrUndefined(userString)) {
      const user = JSON.parse(userString);
      return user.roles['0'];
    }
    return null;
  }
  logout(): void {
    const role = this.getRole();
    localStorage.removeItem('access_token');
    // localStorage.removeItem('expires_in');
    localStorage.removeItem('current_user');
    if (role === ERole.CLIENTE) {
      window.location.href = environment.urlClient.toString();
    } else {
      this.router.navigate(['auth']).then(() => {

      }); 
    }
  }

  getUserId() {
    const userString = localStorage.getItem('current_user');
    if (!isNullOrUndefined(userString)) {
      const user = JSON.parse(userString);
      return user.id;
    }
    return null;
  }

  verifyUiid(uuid: string): Observable<any> {
    return this.http.get<any>(`${environment.API_USERS_URL}/api/verify/${uuid}`);
  }

}
