import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

// const apiUrl = 'http://localhost:8000/api/';
const apiUrl = 'https://api-capacitaciones.kulltest.cl/api/';
// const apiUrl = 'https://api-capacitaciones.test/api/';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private http: HttpClient,
  ) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.log('error--->', error.error, result); // log to console instead

      return of(error.error as T);
    };
  }
  getUser(userId): Observable<any> {
    return this.http.get<any>(apiUrl + 'users/' + userId)
      .pipe(
        catchError(this.handleError('get User', [])),
      );
  }

  getUsers(): Observable<any> {
    return this.http.get<any>(apiUrl + 'users')
      .pipe(
        catchError(this.handleError('get Users', [])),
      );
  }
  storeUser(user): Observable<any> {
    return this.http.post<any>(apiUrl + 'users', user, httpOptions).pipe(
      catchError(this.handleError<any>('store User'))
    );
  }
  updateUser(userId, user): Observable<any> {
    return this.http.put<any>(apiUrl + 'users/' + userId, user, httpOptions).pipe(
      catchError(this.handleError<any>('update User'))
    );
  }
  setUserStatus(userId): Observable<any> {
    return this.http.get<any>(apiUrl + 'users/setstatus/' + userId)
      .pipe(
        catchError(this.handleError('set User Status', [])),
      );
  }
  deleteUser(userId): Observable<any> {
    return this.http.delete<any>(apiUrl + 'users/' + userId)
      .pipe(
        catchError(this.handleError('deleteUser', [])),
      );
  }
  uploadFile(data): Observable<any> {
    const httpOptionsUpload = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post(apiUrl + 'files/normal', data, httpOptionsUpload).pipe(
      tap(_ => console.log('create file')),
      catchError(this.handleError<any>('add File')),
    );
  }
  getCompany(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'companies/' + id)
      .pipe(
        catchError(this.handleError('get company', [])),
      );
  }
  getCompanies(): Observable<any> {
    return this.http.get<any>(apiUrl + 'companies')
      .pipe(
        catchError(this.handleError('get Companies', [])),
      );
  }
  storeCompanie(companie): Observable<any> {
    return this.http.post<any>(apiUrl + 'companies', companie, httpOptions).pipe(
      catchError(this.handleError<any>('store Companie'))
    );
  }
  getCities(): Observable<any> {
    return this.http.get<any>(apiUrl + 'cities')
      .pipe(
        catchError(this.handleError('get Cities', [])),
      );
  }
  getUserWithNoCompany(): Observable<any> {
    return this.http.get<any>(apiUrl + 'users/nocompany')
      .pipe(
        catchError(this.handleError('get User With No Companies', [])),
      );
  }
  addUserCompany(userId, data): Observable<any> {
    return this.http.post<any>(apiUrl + 'companies/' + userId + '/adduser', data, httpOptions).pipe(
      catchError(this.handleError<any>('add User To Company'))
    );
  }
  getUsersCompany(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'companies/' + id + '/users')
      .pipe(
        catchError(this.handleError('get company users', [])),
      );
  }
  updateCompany(companyId, company): Observable<any> {
    return this.http.put<any>(apiUrl + 'companies/' + companyId, company, httpOptions).pipe(
      catchError(this.handleError<any>('update Company'))
    );
  }
  deleteCompany(companyId): Observable<any> {
    return this.http.delete<any>(apiUrl + 'companies/' + companyId)
      .pipe(
        catchError(this.handleError('delete Company', [])),
      );
  }
  deleteUserCompany(userCompanyId): Observable<any> {
    return this.http.delete<any>(apiUrl + 'companies/users/' + userCompanyId)
      .pipe(
        catchError(this.handleError('deleteUserCompany', [])),
      );
  }
  getCourses(): Observable<any> {
    return this.http.get<any>(apiUrl + 'courses')
      .pipe(
        catchError(this.handleError('get Courses', [])),
      );
  }
  getCourseCategories(): Observable<any> {
    return this.http.get<any>(apiUrl + 'courses/categories')
      .pipe(
        catchError(this.handleError('get Course Categories', [])),
      );
  }
  storeCourse(course): Observable<any> {
    return this.http.post<any>(apiUrl + 'courses', course, httpOptions).pipe(
      catchError(this.handleError<any>('store Course'))
    );
  }
  getModules(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'modules/course/' + id)
      .pipe(
        catchError(this.handleError('get Modules', [])),
      );
  }
  storeModule(module): Observable<any> {
    return this.http.post<any>(apiUrl + 'modules', module, httpOptions).pipe(
      catchError(this.handleError<any>('store Module'))
    );
  }
  getModule(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'modules/' + id)
      .pipe(
        catchError(this.handleError('get Module', [])),
      );
  }
  setStatusCourse(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'courses/' + id + '/set-status')
      .pipe(
        catchError(this.handleError('set Status Course', [])),
      );
  }
  getCourseUsers(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'course-users/course/' + id)
      .pipe(
        catchError(this.handleError('get course users', [])),
      );
  }
  asingUsers(data): Observable<any> {
    return this.http.post<any>(apiUrl + 'course-users', data, httpOptions).pipe(
      catchError(this.handleError<any>('store users'))
    );
  }
  getModuleAnswers(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'modules/' + id + '/answers')
      .pipe(
        catchError(this.handleError('get Module Answers', [])),
      );
  }
  getModuleAnswersUser(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'users/client/' + id + '/answers')
      .pipe(
        catchError(this.handleError('get Module Answers', [])),
      );
  }
  getResumeUser(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'users/client/' + id + '/resume')
      .pipe(
        catchError(this.handleError('get Module Answers', [])),
      );
  }
  getUserCourses(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'course-users/user/' + id)
      .pipe(
        catchError(this.handleError('get user courses', [])),
      );
  }
  storeModuleAnswer(answers): Observable<any> {
    return this.http.post<any>(apiUrl + 'answers', answers, httpOptions).pipe(
      catchError(this.handleError<any>('store Module'))
    );
  }
  getModuleUser(id, user_id): Observable<any> {
    return this.http.get<any>(apiUrl + 'modules/' + id + '/answers/' + user_id)
      .pipe(
        catchError(this.handleError('get answer module', [])),
      );
  }
  deleteCourseUser(userId): Observable<any> {
    return this.http.delete<any>(apiUrl + 'courses/users/' + userId)
      .pipe(
        catchError(this.handleError('deleteUser', [])),
      );
  }
  getClients(): Observable<any> {
    return this.http.get<any>(apiUrl + 'clients')
      .pipe(
        catchError(this.handleError('get clients', [])),
      );
  }
  getCourseUsersbyClient(client): Observable<any> {
    return this.http.get<any>(apiUrl + 'students/client/' + client)
      .pipe(
        catchError(this.handleError('get students by client', [])),
      );
  }
  getCourseUsersbyEnterprise(client): Observable<any> {
    return this.http.get<any>(`${apiUrl}clients/${client}/users`)
      .pipe(
        catchError(this.handleError('get students by client', [])),
      );
  }
  getUsersByClient(client): Observable<any> {
    return this.http.get<any>(apiUrl + 'users/client/' + client)
      .pipe(
        catchError(this.handleError('get Users by Client', [])),
      );
  }
  sendReminder(client): Observable<any> {
    return this.http.get<any>(apiUrl + 'users/client/' + client + '/remind')
      .pipe(
        catchError(this.handleError('send remind client', [])),
      );
  }
  storeClientRequest(data): Observable<any> {
    return this.http.post<any>(apiUrl + 'client-request', data, httpOptions).pipe(
      catchError(this.handleError<any>('store client request'))
    );
  }
  getClientRequests(): Observable<any> {
    return this.http.get<any>(apiUrl + 'client-request')
      .pipe(
        catchError(this.handleError('get client-request', [])),
      );
  }
  getActiveCourses(): Observable<any> {
    return this.http.get<any>(apiUrl + 'courses/actives')
      .pipe(
        catchError(this.handleError('ge Active Courses', [])),
      );
  }
  updateClientRequest(id, data): Observable<any> {
    return this.http.put<any>(apiUrl + 'client-request/' + id, data, httpOptions).pipe(
      catchError(this.handleError<any>('update client-request'))
    );
  }
  uploadUser(data, id): Observable<any> {
    const httpOptionsUpload = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post(apiUrl + 'files/' + id, data, httpOptionsUpload).pipe(
      tap(_ => console.log('create file')),
      catchError(this.handleError<any>('add File')),
    );
  }
  uploadFileVideo(data): Observable<any> {
    const httpOptionsUpload = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post(apiUrl + 'files/video', data, httpOptionsUpload).pipe(
      tap(_ => console.log('create video file')),
      catchError(this.handleError<any>('add File')),
    );
  }

  deleteVideo(moduleId): Observable<any> {
    return this.http.delete<any>(apiUrl + 'files/video/' + moduleId)
      .pipe(
        catchError(this.handleError('deleteVideo', [])),
      );
  }

  getCourse(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'courses/' + id)
      .pipe(
        catchError(this.handleError('get Course', [])),
      );
  }
  downloadResume(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'clients/' + id + '/resume')
      .pipe(
        catchError(this.handleError('get Course', [])),
      );
  }
  updateCourse(id, course): Observable<any> {
    return this.http.put<any>(apiUrl + 'courses/' + id, course, httpOptions).pipe(
      catchError(this.handleError<any>('update Course'))
    );
  }
  updateModule(id, module): Observable<any> {
    return this.http.put<any>(apiUrl + 'modules/' + id, module, httpOptions).pipe(
      catchError(this.handleError<any>('update Module'))
    );
  }
}
