import { UsersListResumenComponent } from './clients/users/users-list-resumen/users-list-resumen.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { RequestsComponent } from './views/pages/requests/requests.component';
import { AdminStudentsComponent } from './views/pages/admin-students/admin-students.component';
import { AdminDashboardComponent } from './views/pages/admin-dashboard/admin-dashboard.component';
import { AprovedComponent } from './views/pages/requests/aproved/aproved.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'dashboard/:client_id',
    component: AdminDashboardComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/:client_id/resume',
    component: UsersListResumenComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () => import('./views/pages/users/users.module').then(m => m.UsersModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'companies',
    loadChildren: () => import('./views/pages/companies/companies.module').then(m => m.CompaniesModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'courses',
    loadChildren: () => import('./views/pages/courses/courses.module').then(m => m.CoursesModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'requests',
    component: RequestsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'requests/aproved',
    component: AprovedComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'admin-students',
    component: AdminStudentsComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    runGuardsAndResolvers: 'always',

  },
  {
    path: 'students',
    loadChildren: () => import('./student/student.module').then(m => m.StudentModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'clients',
    loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
