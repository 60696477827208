import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EStatus, statusErros } from '../constants/status-response.constant';
import { IHttpResponse } from '../interfaces/http-response.interface';
import { HttpResponseService } from '../services/http-response.service';

@Injectable()
export class ResponseHandlerInterceptor implements HttpInterceptor {
    constructor(private httpResponseService: HttpResponseService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('interceptor....', request);
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('successfull', event);
                    // if (event.status !== EStatus.VERIFICATE) {
                    if (event.status === EStatus.OK || event.status === EStatus.VERIFICATE) {
                        const response: IHttpResponse = {
                            status: event.status,
                            message: event.body.message,
                            success: event.body.success ? event.body.success : null,
                            verification: event.body.verification ? event.body.verification : null,
                        }
                        console.log(response)
                        this.httpResponseService.showStatus(response);
                    }
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse) {
                    if (statusErros.includes(error.status)) {
                        const response: IHttpResponse = {
                            status: error.status,
                            message: error.message,
                            success: error.error.success ? error.error.success : null,
                            verification: error.error.verification ? error.error.verification : null,
                        }
                        this.httpResponseService.showStatus(response);
                    }
                }
                return throwError(error);
            })
        );
    }
}