import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IHttpResponse } from '../interfaces/http-response.interface';

@Injectable({
  providedIn: 'root'
})
export class HttpResponseService {

  responseEvent: Subject<IHttpResponse> = new Subject<IHttpResponse>();

  constructor() { }

  showStatus(response: IHttpResponse) {
    this.responseEvent.next(response);
  }
}
