import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from './../../../services/notification-service';
import { NotificationType } from './../../../models/Notification';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { ApiService } from '../../../services/api.service';
import * as moment from 'moment'; // add this 1 of 4
import { Select2OptionData } from 'ng2-select2';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  courses: any;
  cursos: any;
  public clients: Array<Select2OptionData>;
  displayedColumns: string[] = ['name', 'lastName', 'email', 'progress', 'timelapse', 'timefinish', 'actions'];
  dataSource: any;
  requests: any;
  hide1: any;
  value: any;
  current: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private api: ApiService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Items por página:';
    this.paginator._intl.firstPageLabel = 'Primera página';
    this.paginator._intl.lastPageLabel = 'Última página';
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator.pageSize = 20;
    this.loadClients();
    this.current = this.route.snapshot.paramMap.get('client_id');
    this.selectClient(this.current)
  }

  async loadClients() {
    let clients = await this.api.getClients().toPromise();
    clients = clients.object;
    this.clients = clients.map((client) => {
      return {
        id: client.id,
        text: client.nombre
      };
    })
  }

  public changeValue() {
    console.log('change');
  }

  async loadUsers(id) {
    this.dataSource = new MatTableDataSource([]);
    this.requests = await this.api.getUsersByClient(id).toPromise();
    this.requests = this.requests.object;
    this.requests = _.filter(this.requests, { active: 1, student: 1 });

    this.cursos = await this.api.getCourses().toPromise();
    this.cursos = this.cursos.object;
    this.cursos = _.filter(this.cursos, { active: 1 });
    this.cursos = _.orderBy(this.cursos, 'position', 'asc');

    //console.log(this.requests);
    
    this.requests = this.requests.map((request) => {
      request.progress = 0;
      const today = moment();
      const endDate = moment(request.courses[0].end);
      request.endDate = endDate;
      request.fromDay = moment(endDate).diff(moment(today), 'days');
      if (request.courses.length > 0) {
        // request.intentos = request.cursos.filter(current => current.try).reduce(sum => sum += 1, 0);
        request.progress = ((request.courses.filter(current => current.try).reduce(sum => sum += 1, 0) / this.cursos.length) * 100).toFixed(2);
        if (request.progress < 20) {
          request.progressClass = 'bg-danger';
        }
        if (request.progress >= 21 && request.progress <= 40) {
          request.progressClass = 'bg-warning';
        }
        if (request.progress >= 41 && request.progress <= 80) {
          request.progressClass = 'bg-primary';
        }
        if (request.progress >= 81 && request.progress <= 100) {
          request.progressClass = 'bg-success';
        }
      }

      // Para obtener su ponderacion del usuario
      let ponderacion = 0;
      const currentUser = `${request.detail[0].name} ${request.detail[0].last_name}`;
      
      request.respuestas = JSON.parse(JSON.stringify(this.cursos));
      request.respuestas = _.orderBy(request.respuestas, 'position', 'asc');
      request.respuestas.map((course) => {
        const ponderations = this.cursos.find(c => {
          return c.id === course.id;
        });
        const currentCourse = request.courses.find(c => {
          return c.course_id === course.id;
        });
        if (currentCourse && course.id == currentCourse.course_id) {
          if (currentCourse.approved === 1) {
            course.approved = 'si';
            if (!ponderations.introduction) {
              ponderacion += parseFloat(ponderations.ponderation_approved);
              // console.log('ponderation_approved-' + request.id + "--" + parseFloat(ponderations.ponderation_approved)  + "--" + currentCourse.id + "--" + course.id);
            }
          } else if (currentCourse.approved === 0 && currentCourse.try > 0) {
            course.approved = 'no';
            if (!ponderations.introduction) {
              ponderacion += parseFloat(ponderations.ponderation_repproved);
              // console.log('ponderation_repproved-' + request.id + "--" + parseFloat(ponderations.ponderation_repproved) + "--" + currentCourse.id + "--" + course.id);
            }
          }
          else if (currentCourse.approved === 0 && !currentCourse.try) {
            course.approved = '-';
            if (!ponderations.introduction) {
              // console.log('ponderation_-' + request.id + "--"  + 0 + "--" + currentCourse.id + "--" + course.id);
              ponderacion += 0;
            }
          }
          // course.approved = currentCourse.approved && course.finish_course !== null;
        }
      });
      //console.log(request.id + "-", ponderacion);
      request.respuestas[0].alumno = currentUser;
      request.approved = Math.round(ponderacion);
      
      return request;
    });

    this.renderTable();
  }
  renderTable() {
    this.dataSource = new MatTableDataSource(this.requests);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    //console.log(this.dataSource);
    
  }

  navigateTo(option) {
    console.log(option.value);
    const redirectTo = `/dashboard`
    console.log(redirectTo);
    
    if (redirectTo) {
      setTimeout(() => {
        this.router.navigate([redirectTo, option.value])
        .catch(err => console.log('err', err))
        .then(() => window.location.reload())
      }, 100);
    }
    // return false;
}

  async selectClient(clientId) {
    this.loadUsers(clientId);
    this.courses = await this.api.getCourseUsersbyEnterprise(clientId).toPromise()
    this.courses = this.courses.object;
    console.log(this.courses);
  }

  async sendReminder(id){
    this.notificationService.sendMessage({
      message: 'Enviando notificación',
      type: NotificationType.info
    });
    try {
      const reminder = await this.api.sendReminder(id).toPromise();
      console.log(reminder);
      
      if (reminder.code === 200){
        this.notificationService.sendMessage({
          message: 'Notificación enviada',
          type: NotificationType.success
        });
      } else {
        this.notificationService.sendMessage({
          message: 'Error al enviar la notificiación',
          type: NotificationType.error
        });
      }

    } catch (error) {
      this.notificationService.sendMessage({
        message: 'Error al enviar la notificiación',
        type: NotificationType.error
      });
    }

  }

}
